import i18n from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { getAdminUser } from '../utils/common'
import { VERSION } from '../configs/app'



import styled from 'styled-components'
import IconButton from '../components/common/IconButton'
import Loading from '../components/common/Loading'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'


import { AiOutlineApartment } from "react-icons/ai";
import { FaRegStar } from "react-icons/fa";
import { TbUserUp } from "react-icons/tb";

import { FiUsers,FiLayers,FiBarChart,FiActivity } from 'react-icons/fi'
import { FiLogOut } from 'react-icons/fi'
import { RiHome6Line } from 'react-icons/ri'
import logo from '../assets/images/logo.svg'
import logo_menu from '../assets/images/logo_menu.png'


import { logoutAdmin } from '../redux/actions/admin'
//import { setIsRedirect } from '../redux/actions/master'

import {
  headerAccountManage,
  headerActivity,
} from '../configs/headerMenu.js'
import { 
  subMenuHome,
  subMenuAccountManage,
  subMenuActivity,
} from '../configs/subMenu'

const Div = styled.div`
  display: flex;
  overflow: hidden;

  min-height: 100%;
  height: 100vh;

  .load-more-btn {
    margin-top: 70px;
    padding: 10px 30px;
    align-items: center;
    color: #6941c6;
    border: 1px solid #e9d7fe;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: #f9f5ff;
    width: 128px;
    height: 36px;
    cursor: pointer;
  }
  .badge-for-noti {
    padding: 2px 8px;
    align-items: center;
    color: var(--Pink-700);
    background: var(--Pink-50);
    border: 1px solid var(--Pink-200);
    border-radius: 16px;
    margin-left: 60px;
  }
  .icon {
    cursor: pointer;
  }
  .mr-2-rem {
    margin-right: 2rem;
  }
  .mr-8-px {
    margin-right: 8px;
  }

  .side-panel-container {
    display: block;
    padding: 2rem;
    min-width: 100%;
  }
  .d-flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }

  .create-access-role-header {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .icons {
    height: 100%;
    width: 70%;
    cursor: pointer;
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }

  > .side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid var(--Gray-200);

    height: 100%;
    overflow-y: auto;

    box-sizing: border-box;

    .top-wrapper {
      flex: 1;
      display: flex;

      .main-menu {
        padding: 24px 16px;
        background: var(--Side-Menu-Bg);

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .logo {
          width: 100%;
          justify-content: center;
          margin-bottom: 16px;
        }

        .menu {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;
          width: 48px;
          height: 48px;
          border-radius: 6px;

          &:last-child {
            margin-bottom: 0;
          }

          .icon-default {
            color: var(--Side-Menu-Icon);
          }

          &.active {
            background: var(--Side-Menu-Icon-Select-Bg);

            path {
              /* stroke: var(--Side-Menu-Icon-Select); */
            }

            .icon-default {
              color: var(--Side-Menu-Icon-Select);
            }
          }
        }
        .menu-notifications {
          position: relative;
          .notification-icon {
            color: var(--Side-Menu-Icon) !important;
          }
          .notification-badge {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background-color: red;
            border-radius: 50%;
            font-size: 10px;
            font-weight: bold;
            color: #ffffff;

            position: absolute;
            right: 0;
            top: 4px;
          }
        }
      }

      .sub-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 24px 16px;
        background: var(--Side-Menu-Open-Bg-Default);
        width: ${(props) => props.widthSideMenu ?? 340}px;

        .header-menu {
          display: flex;
          align-items: center;
          gap: 12px;
          color: var(--Side-Menu-Open-Font-Default);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;

          .icon-header {
            font-size: 24px;
          }
        }

        .menu {
          cursor: pointer;
          display: block;
          margin-top: 4px;
          padding: 8px 12px;
          border-radius: 6px;
          color: var(--Side-Menu-Open-Font-Default);
          font-size: 16;
          font-weight: 600;
          text-decoration: none !important;

          &.active {
            color: var(--Side-Menu-Open-Font-Active);
            background: var(--Side-Menu-Open-Bg-Active);
          }
          &:hover {
            background: var(--Side-Menu-Open-Bg-Hover);
          }
          .icon-header {
            font-size: 24px;
            vertical-align: sub;
            margin-right: 8px;
          }
        }
        .div_company_change {
          width: 100%;
          margin: 15px 0px;
        }
        .current-user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: var(--Base-White);
          bottom: 0;

          .img-profile {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            object-fit: cover;
          }

          .detail {
            flex: 1;
            font-size: 14px;

            .email {
              color: var(--Primary-200);
              font-weight: 400;
            }
          }

          .logout {
            color: var(--Primary-300);
          }
        }
      }
    }
  }

  > .main-content {
    position: relative;

    flex: 1;
    padding: 24px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;

    > .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      background: var(--Base-White);
      border-top: 1px solid var(--Gray-200);
      color: var(--Gray-500);
      font-size: 0.875rem;

      display: flex;
      justify-content: space-between;
      flex-shrink: 0;

      padding: 1.5rem;
    }

    > .footer-mobile-tablet {
      position: relative;

      background: var(--Base-White);
      border-top: 1px solid var(--Gray-200);
      color: var(--Gray-500);
      font-size: 0.875rem;

      padding: 1.5rem;

      .what-new-user-guide-container {
        display: flex;
        align-items: center;
      }

      .user-guide {
        color: #0086c9;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .what-new {
        color: #444ce7;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .content-block {
    flex: 1 1 auto;
    overflow-y: auto;
    margin-bottom: 4rem;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .tab-container {
    display: flex;
    border-bottom: 2px solid rgb(234, 236, 240);
    width: 35%;
    .tab-item {
      cursor: pointer;
      margin-right: 2rem;
      padding-bottom: 1rem;
      padding: 10px;
      &.active {
        color: #7f56d9;
        font-weight: 600;
        border-bottom: 2px solid #7f56d9;
        background-color: rgb(127 86 217 / 13%);
        padding: 10px;
      }
    }
  }

  .notifications {
    border: 1px solid rgb(245 245 245);
    margin: 0px -30px 0px;
  }

  .list-group {
    list-style: none;
    padding: 0;
  }

  .list-group-item {
    border: none;
    background-color: rgb(255 255 255);
    padding: 10px;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  .list-group-item:hover {
    background-color: #f3f3f3;
  }

  .notification-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .notification-sender {
    font-weight: bold;
    margin-right: 10px;
  }

  .notification-action {
    font-size: 14px;
    margin-right: 10px;
  }

  .notification-due-date {
    font-size: 14px;
    span {
      color: #7f56d9;
      font-weight: 600;
    }
  }

  .notification-body {
    margin-bottom: 10px;
  }

  .notification-task,
  .notification-project {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
  }

  .notification-footer {
    color: #999;
    font-size: 14px;
  }

  .notification-date {
    font-weight: bold;
  }

  .img-profile {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: #ffffff;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #000000;
    cursor: pointer;
  }
  .backdrop_NotificationSide {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  .lang-container {
    border: 1px solid var(--Base-White);
    border-radius: 8px;
    padding: 4px;
    color: var(--Base-White);

    .divide {
      display: inline-block;
      width: 1px;
      height: 15px;
      background-color: var(--Base-White);
      margin: 0 4px;
    }

    .selector {
      cursor: pointer;

      &.active {
        background: var(--Base-White);
        color: var(--Primary-600);
        border-radius: 4px;
        padding: 2px;
      }
    }
  }

  .hightlight {
    color: var(--Base-Font-Focus-Color);
    font-weight: 800;
  }

  .user-guide {
    color: #0086c9;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .what-new {
    color: #444ce7;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .group-side-menu-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 1023px) {
    .visible {
      display: block !important;
    }

    .logo {
      display: none;
    }

    .side-menu {
      display: none;

      .top-wrapper {
        .main-menu {
          padding: 70px 8px 24px;
          min-height: 100vh;
        }

        .sub-menu {
          position: fixed;
          left: 82px;
          height: 100%;

          z-index: 98;
          padding: 90px 16px 24px;
        }
      }
    }

    .main-content {
      padding: 0;
      margin-top: 64px;
    }

    .left-0 {
      left: 0;
    }
  }

  @media screen and (max-width: 434px) {
    .main-content {
      > .footer-mobile-tablet {
        font-size: 11px;
      }
    }
  }
`

const AdminLayout = ({ headerMenu, subMenu = [] }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const user = getAdminUser()
  const all_menu_list = user?.menu_list ? user?.menu_list : [];

  const [isLoading, setIsLoading] = useState(false)
  const [isShowSideMenu, setIsShowSideMenu] = useState(false)
  const [headerMenuState, setHeaderMenuState] = useState(headerMenu)
  const [subMenus, setSubMenus] = useState(subMenu)
  const [subMenuName, setSubMenuName] = useState('')
  const [currentPath, setCurrentPath] = useState('')
  const [lang, setLang] = useState()

  const [isShowSideMenuForHamburger, setIsShowSideMenuForHamburger] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [widthSideMenu, setWidthSideMenu] = useState(null)


  useEffect(() => {
    setCurrentPath(`${location.pathname}${location.search}`)
    const url = location.pathname.split('/')
    if (url.length < 3 && url[0] !== url[1]) {
      setIsShowSideMenu(true)
    } else {
      setIsShowSideMenu(false)
    }
    if (url[1] !== '') setSubMenuName(url[1])
    else setSubMenuName('home')
  }, [location.pathname])

  // modal & sidepanel
  const logout = () => {
    //dispatch(setIsRedirect(null))
    dispatch(logoutAdmin())
  }
  const togglePanel = () => {
    setIsOpenNotificationSide((prev) => !prev)
    if (windowWidth < 1024) {
      setwidthNonificationSide(windowWidth)
    } else {
      setwidthNonificationSide(488)
    }
  }
  const CloseNotiPanel = () => {
    setIsOpenNotificationSide(false)
    setwidthNonificationSide(0)
  }
  const toggleSubmenuFromImageUser = () => {
    setIsShowSideMenu(!isShowSideMenu)
  }
  const toggleSideMenuforHamburger = () => {
    setIsShowSideMenuForHamburger(!isShowSideMenuForHamburger)
    setIsShowSideMenu(!isShowSideMenuForHamburger)
  }

  // optional
  const navigateTo = (path) => {
    navigate(path)
    setIsShowSideMenu(false)
    //toggleSideMenuforHamburger()
  }
  const toggleSubmenu = (key) => {
    if (isShowSideMenuForHamburger) setIsShowSideMenu(true)
    else if (subMenuName === key) setIsShowSideMenu(!isShowSideMenu)
    else setIsShowSideMenu(true)

    setSubMenuName(key)
    const this_sub_menu = all_menu_list.filter((Obj) => Obj.main_menu_name == key)[0]?.sub_menu  ;
    //alert(JSON.stringify(this_sub_menu))
    switch (key) {
      case 'home':
        setHeaderMenuState()
        setSubMenus(subMenuHome)
        break
      case 'AccountManage':
        setHeaderMenuState(headerAccountManage)
        //setSubMenus(subMenuAccountManage)
        setSubMenus(this_sub_menu)
        break
      case 'Activity':
        setHeaderMenuState(headerActivity)
        //setSubMenus(subMenuActivity)
        setSubMenus(this_sub_menu)
        break
      default:
        setSubMenus(subMenuHome)
    }
  }

  const ReturnIconMenu = (inp_text) => {
    switch (inp_text) {
      case '<AiOutlineApartment />':
        return <AiOutlineApartment />
      case '<FaRegStar />':
        return <FaRegStar />
      case '<TbUserUp />':
        return <TbUserUp />  
      case '<FiBarChart />':
          return <FiBarChart />
     case '<FiActivity />':
          return <FiActivity />
      default:
        return <div></div>
    }
  }


  return (
    <Div widthSideMenu={widthSideMenu}>

      <div className={`side-menu ${isShowSideMenuForHamburger ? 'visible' : ''}`}>
        <div className="top-wrapper">
          <div className="main-menu">
            <div className="mb-1-rem">
              <IconButton className="logo" src={logo_menu} />
              <Tooltip title="Home" placement="right" arrow>
                <div
                  className={`menu ${subMenuName === 'home' ? 'active' : ''}`}
                  onClick={() => toggleSubmenu('home')}
                >
                  <RiHome6Line className="icon-default" size={28} />
                </div>
              </Tooltip>

              <Tooltip title="Activity" placement="right" arrow>
                <div
                  className={`menu ${subMenuName === 'Activity' ? 'active' : ''}`}
                  onClick={() => toggleSubmenu('Activity')}
                >
                  <FiLayers className="icon-default" size={28} />
                </div>
              </Tooltip>

              <Tooltip title="AccountManage" placement="right" arrow>
                <div
                  className={`menu ${subMenuName === 'AccountManage' ? 'active' : ''}`}
                  onClick={() => toggleSubmenu('AccountManage')}
                >
                  <FiUsers className="icon-default" size={28} />
                </div>
              </Tooltip>

            </div>

            <div className="group-side-menu-bottom">
              <div className="user_img_char_1" onClick={toggleSubmenuFromImageUser}>
                  <h3>{user?.full_name ? user?.full_name?.substring(0, 1) : 'A'}</h3>
              </div>
            </div>
          </div>

          {isShowSideMenu && (
            <div className={`sub-menu`}>
              <div>
                {headerMenuState && (
                  <div className="header-menu mb-1-rem">
                    <span className="icon-header">{headerMenuState.icon}</span>
                    <span>{lang === 'th' ? headerMenuState.name_th : headerMenuState.name}</span>
                  </div>
                )}
                {subMenus.map((v) => (
                  <div
                    key={v.path}
                    className={`menu ${
                      (currentPath?.includes(v?.path) && currentPath !== '/') ||
                      (currentPath == '/' && v.text == 'Home')
                        ? 'active'
                        : ''
                    }`}
                    onClick={() => navigateTo(v.path)}
                  >
                    <span className="icon-header">
                      {v.icon ? ReturnIconMenu(v.icon) : v.icon_img ? <img src={v.icon_img} /> : ''}
                    </span>
                    <span>{v.text} </span>
                                       
                  </div>
                ))}
              </div>
              <div>
                <div className="current-user">
                  <div className="detail">
                    <div className="roles m">
                      {user?.full_name}
                    </div>
                    <div className="email">{user?.email}</div>
                  </div>
                  <FiLogOut className="logout icon" size="20" onClick={logout} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`main-content ${isShowSideMenuForHamburger ? 'left-0' : ''}`}>
        <div className="content-block">{isLoading ? <Loading /> : <Outlet />}</div>

        <div className="footer">
            <div>
              <div>
                You are now active in{' '}
                <span className="hightlight">
                {user?.organizations_name ? user?.organizations_name : '-'}
                </span>
              </div>
            </div>
            

            <div className="d-flex align-items-center">
              <div>version {VERSION}</div>
            </div>
          </div>
      </div>
    </Div>
  )
}

export default AdminLayout
